@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Readex+Pro:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Readex+Pro:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Readex+Pro:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ja-candidate-nurture-coach-border-radius {
  border-radius: 1rem;
}

.ja-candidate-nurture-button-border-radius {
  border-radius: 0.5rem;
}

.ja-candidate-nurture-coach-box-shadow {
  box-shadow: 0 0.625rem 1.563rem rgba(0, 0, 0, 0.16);
}

.ja-candidate-nurture-button {
  background-color: #01A4A4;
  border: 1px solid #01A4A4;
  box-shadow: 0 0.125rem 0.188rem rgba(0, 0, 0, 0.16);
}

.ja-candidate-nurture-button:hover {
  background-color: #056d6d;
  border: 1px solid #056d6d;
}

.ja-candidate-nurture-button:active {
  background-color: #056d6d !important;
  border: 1px solid #056d6d;
  border-color: #056d6d;
}

.img-coach-mobile {
  box-shadow: 0 0.125rem 0.188rem rgba(0, 0, 0, 0.16);
}

.img-coach-mobile:active {
  border: 2px solid #01A4A4;
}

.JA-Custom-Collapse-Image {
  right: 1.5rem;
  top: 1.5rem;
  width: 1.5rem;
}

.JA-cursor-pointer {
  cursor: pointer;
}

.JA-candidate-nurture-popover-wrapper .popover {
  transform: translate3d(140px, -102px, 0px) !important;
  width: 60%;
  border-radius: 1rem;
  padding: 0.5rem;
  box-shadow: 0 0.125rem 0.188rem rgba(0, 0, 0, 0.16);
}

.JA-coach-avatar {
  max-width: 80%;
}

.JA-button-primary {
  background-color: #01a3a4 !important;
  border-color: #01a3a4;
  transition: all 0.2s ease-out;
  border-radius: 0.2rem;
  box-shadow: 0 0.25rem 0.55rem rgba(1, 163, 164, 0.35);
}

.JA-button-primary:hover {
  box-shadow: 0 0.22rem 0.525rem rgba(1, 163, 164, 0.4), 0 0.0625rem 0.385rem rgba(1, 163, 164, 0.54);
  border-color: #01a3a4;
}

button.JA-button-primary:hover {
  background-color: #017171 !important;
  text-decoration: none;
}

button.JA-button-primary:active {
  background-color: #017171 !important;
  text-decoration: none;
}

button.JA-button-primary:focus {
  background-color: #017171 !important;
  text-decoration: none;
}

.JA-button-primary-outline {
  border-color: #01a3a4;
  border-radius: 0.2rem;
  box-shadow: 0 0.25rem 0.55rem rgba(1, 163, 164, 0.35);
}

.JA-button-primary-outline:hover {
  box-shadow: 0 0.22rem 0.525rem rgba(1, 163, 164, 0.4), 0 0.0625rem 0.385rem rgba(1, 163, 164, 0.54);
  border-color: #01a3a4;
}

.JA-text-primary {
  color: #01a3a4 !important;
}
.JA-Go-Back-Button {
  color: #01A4A4;
}

.JA-Go-Back-Button:hover {
  text-decoration: underline;
  color: #01A4A4;
}

.JA-Go-Back-Button:disabled {
  color: #01A4A4;
}

.JA-Onboard-Quiz-Next-Button {
  padding: 0px 7rem;
}

.content-box {
  margin-top: 10rem;
  padding-left: 4rem;
}

.complete-message {
  color: #422134;
  font-family: "Readex Pro", sans-serif;
  font-size: 4rem;
  line-height: 92%;
}

.welcome-message {
  font-size: 1rem;
  font-family: "Readex Pro", sans-serif;
  color: #422134;
}

.circle {
  z-index: -999;
  right: -1rem;
  top: 4rem;
}

.set-password {
  font-family: "Readex Pro", sans-serif;
}
.set-password h1 {
  color: #21111A;
  font-weight: 700;
  font-size: 2.875rem;
}
.set-password form {
  max-width: 18.75rem;
  margin: 0 auto;
}
.set-password form input[type=password] {
  height: 3rem;
  border: 1px solid #DDDAD6;
  outline: none;
  border-radius: 4px;
}
.set-password form input[type=password]:focus {
  border: 1px solid #01A4A4;
  box-shadow: none;
}
.set-password form input[type=password].error {
  border: 1px solid red;
}
.set-password form .error-message {
  color: #fff;
  background-color: #f04848;
  font-size: 0.85rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}
.set-password form .form-group.password {
  position: relative;
}
.set-password form .form-group.password .input-password:focus + .password-required {
  display: block;
}
.set-password form .form-group.password .password-required {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 1rem;
  transform: translateY(calc(-100% + 5px));
  border-radius: 4px;
  background-color: #20252f;
}
.set-password form .form-group.password .password-required p {
  color: #fff;
  margin-bottom: 0;
  font-size: 0.875rem;
}
.set-password form .form-group.password .password-required p.text-green {
  color: #72bb24;
}
.set-password form .form-group.password .password-required p.text-red {
  color: #ae4237;
}
.set-password form .form-group.password .password-required::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  transform: translateY(100%);
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid #20252f;
}

@media only screen and (max-width: 576px) {
  .JA-Onboard-Quiz-Next-Button {
    width: 100%;
    padding: 0;
  }
  .content-box {
    margin-top: 5rem;
    padding-left: 0;
  }
  .circle {
    right: 1rem;
    top: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .content-box {
    margin-top: 5rem;
  }
  .circle {
    right: 1rem;
    top: 1rem;
  }
  .complete-message {
    font-size: 3rem;
  }
}
.form-group.active {
  border-color: #1F1D1A !important;
}
.form-group label, .form-group input[type=radio] {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.form-group .drop-box {
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  border: 3px solid #DDDAD6;
  border-style: dashed;
}
.form-group .drop-box span {
  color: #01A4A4;
}
.form-group input.form-check-input {
  border: 1px solid #21111A;
}
.form-group input.form-check-input:checked, .form-group input.form-check-input:active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e");
  border-color: #21111A;
  box-shadow: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #01a3a4;
  border-color: #01a3a4;
}

.JA-Answer-Description {
  line-height: 1.2;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #f83245;
}

.Toastify__toast-icon {
  display: none;
}
/*--------Color Mapping----------------------------
    #EBF3FF	blue-100
    #BFE8FF	blue-100
    #BFE8FE	blue-100
    #D0E4FF	blue-100
    #52C0E5	blue-300
    #021BFF	blue-800
    #035ADE	blue-800
    #4F52C5	blue-800
    #3C44B1	blue-800
    #E0FDEC	green-100
    #DFF9EC	green-100
    #D5F6F3	green-100
    #CDFFE2	green-100
    #D9F1F1	green-100
    #E0FFFF	green-100
    #4ED485	green-300
    #01A3A4	green-400
    #01A4A4	green-400
    #F5F5F5	neutral-100
    #FAFAFA	neutral-100
    #F4F4F4	neutral-100
    #F2FAFD	neutral-100
    #F9F9F9	neutral-100
    #E9ECF1	neutral-200
    #F2F2F2	neutral-200
    #F5F4F2	neutral-200
    #F1F4F8	neutral-200
    #EFEFFF	neutral-200
    #F1F1F1	neutral-200
    #E9E9E9	neutral-200
    #E5E5E5	neutral-300
    #D3D4DC	neutral-300
    #EDEBE8	neutral-300
    #DDDAD6	neutral-300
    #D6DBE4	neutral-300
    #D2D2D2	neutral-400
    #D1D2DB	neutral-400
    #ADB2BA	neutral-400
    #C2C4C6	neutral-400
    #ADB2B9	neutral-400
    #D3D3D3	neutral-400
    #D5D5D5	neutral-400
    #C4C4C4	neutral-400
    #A2A2A2	neutral-500
    #979797	neutral-500
    #969798	neutral-500
    #897F86	neutral-500
    #959BA3	neutral-500
    #98A0AB	neutral-500
    #828B93	neutral-600
    #797672	neutral-600
    #8F96A0	neutral-600
    #79808D	neutral-600
    #6E5E5E	neutral-700
    #6B6A66	neutral-700
    #FFDD88	orange-100
    #FFDFC8	orange-100
    #FFD993	orange-100
    #FFE173	orange-100
    #FFC86E	orange-200
    #F5D06B	orange-200
    #FFBA49	orange-300
    #FFB949	orange-300
    #FFF2CE	orange-50
    #FFFBE6	orange-50
    #FFFBF2	orange-50
    #FFF1CD	orange-50
    #FFF0D4	orange-50
    #FFF6CA	orange-50
    #FF9E0C	orange-500
    #FF8F00	orange-600
    #818BA4	purplish-500
    #7A7B97	purplish-500
    #AF5E6D	purplish-500
    #6084B6	purplish-500
    #4E5A6B	purplish-600
    #364256	purplish-600
    #415066	purplish-600
    #1E2C42	purplish-800
    #1F2C41	purplish-800
    #3B3E66	purplish-800
    #371C36	purplish-800
    #343434	purplish-800
    #3A2935	purplish-800
    #2B3444	purplish-800
    #1A4688	purplish-800
    #000000	purplish-900
    #070919	purplish-900
    #21191D	purplish-900
    #26131F	purplish-900
    #FFAE8B	red-100
    #FDC7C4	red-100
    #FF6285	red-300
    #FF73C7	red-300
    #FFF3E9	red-50
    #FFEFEF	red-50
    #FC6E47	red-500
    #F86158	red-600
    #F45255	red-600
    #F83245	red-600
    #FF3366	red-600
    #B11E1E	red-900
    #FFFFFF	neutral-50
------------------------------------*/
/*--------Old Color Variables----------------------------
    $Salmon:            #F86158;
    $Midnight:          #1F2C41;
    $Eggshell:          #FFF2CE;
    $Gold:              #FFBA49;
    $White:             #FFFFFF;
    $OxfordBlue:        #364256;
    $ShadowBlue:        #818BA4;
    $Solitude:          #E9ECF1;
    $Snow:              #F9F9F9;
    $Emerald:           #4ED485;
    $GoldenGlow:        #FFDD88;
    $PeachGlow:         #FFDFC8;
    $Black_30:          #21111A; // not used
    $RedInvited:        #FC6E47; // not used
    $BlueSent:          #6084B6; // not used
    $BlueCompleted:     #035ADE; // not used
    $GreenLight:        #4ED485; // not used
------------------------------------*/

.chip-input__wrapper {
  color: #21111A;
  width: 100%;
  max-width: 22rem;
}
.chip-input__wrapper .input-wrapper {
  border: 1px solid #DDDAD6;
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  border-radius: 0.25rem;
}
.chip-input__wrapper .input-wrapper:focus-within {
  border-color: #01A4A4;
}
.chip-input__wrapper .input-wrapper input {
  color: #21111A;
  border: none;
  outline: none;
  flex: 1 1;
}
.chip-input__wrapper .icon {
  cursor: pointer;
  flex-shrink: 0;
}
.chip-input__wrapper .chips {
  margin-top: 1rem;
}
.chip-input__wrapper .chips .chip {
  display: inline-flex;
  padding: 0.3rem 0.625rem;
  border: 2px solid #21111A;
  border-radius: 40px;
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
}
.chip-input__wrapper .chips .chip span {
  margin-right: 0.375rem;
}
.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #01A4A4;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Book-Session-Button {
  border-radius: 0.5rem !important;
  background: #01A3A4;
  border-color: #01A3A4;
  padding: 0.55rem 0.75rem;
}

.Book-Session-Button:hover {
  background-color: rgb(4, 139, 139);
  border-color: #01A3A4;
}

.Book-Session-Button:active {
  background-color: rgb(4, 139, 139) !important;
  border-color: #01A3A4;
}

.Book-Session-Button:disabled {
  background-color: #0cb4b5 !important;
  border-color: #01A3A4;
}
.header-feedback {
  width: 100%;
  font-family: "Readex Pro", sans-serif;
  position: relative;
}
.header-feedback-image {
  width: 100%;
  object-fit: fill;
}
.header-feedback-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.header-feedback-text h1 {
  font-size: 4rem;
  font-weight: 700;
  color: #211B21;
  margin-top: 2rem;
}
.header-feedback-text h1.isSubmitted {
  font-size: 3rem;
}
.header-feedback-logo {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .header-feedback-text h1 {
    font-size: 3rem;
  }
  .header-feedback-text h1.isSubmitted {
    font-size: 2rem;
  }
}
@media (max-width: 575.98px) {
  .header-feedback-text h1 {
    font-size: 2.25rem;
  }
  .header-feedback-text h1.isSubmitted {
    font-size: 1.5rem;
  }
  .header-feedback-image {
    height: 12.5rem;
    object-fit: cover;
  }
}
.rating-class > span:not(:last-child) {
  margin-right: 0.25rem;
}

.rating-class span img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
}

.rating-text {
  display: inline-block;
  width: 4.375rem;
  flex-shrink: 0;
  font-size: 0.75rem;
  font-weight: 360;
  color: #01A4A4;
  margin-left: 0.75rem;
  line-height: 1rem;
}
.rating-text-none {
  color: #DDDAD6;
}
.rating-number-wrapper {
  border: 1px solid #42B4B5;
  border-radius: 4px;
}
.rating-number-wrapper .rating-item {
  flex: 1 1;
  height: 48px;
  color: #01A4A4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.rating-number-wrapper .rating-item:hover {
  background-color: #01A4A4;
  color: #fff;
  box-shadow: 0px 3px 0px 1px #01A4A4, 0px -3px 0px 1px #01A4A4;
}
.rating-number-wrapper .rating-item.active {
  background-color: #01A4A4;
  color: #fff;
  box-shadow: 0px 3px 0px 1px #01A4A4, 0px -3px 0px 1px #01A4A4;
}

.rate-description .horizontal-line {
  width: -webkit-fill-available;
  border-top: 1px dashed #01A4A4;
  flex-grow: 1;
}
.rate-description .string-low {
  flex-shrink: 0;
  min-width: 0;
  margin-right: 0.5rem;
  color: #01A4A4;
  font-size: 0.75rem;
  font-weight: 360;
}
.rate-description .string-high {
  flex-shrink: 0;
  margin-left: 0.5rem;
  min-width: 0;
  color: #01A4A4;
  font-size: 0.75rem;
  font-weight: 360;
}

@media screen and (max-width: 580px) {
  .rating-number-wrapper .rating-item {
    width: 2.1rem !important;
  }
}
.select-float {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0 1.5rem;
  font-size: 0.875rem;
  pointer-events: none;
  transition: all 0.2s ease-in;
}
.select-float.active {
  top: 0;
  left: 1rem;
  padding: 0 0.5rem;
  font-size: 0.625rem;
  background-color: #fff;
  color: #01A4A4;
}
/*--------Color Mapping----------------------------
    #EBF3FF	blue-100
    #BFE8FF	blue-100
    #BFE8FE	blue-100
    #D0E4FF	blue-100
    #52C0E5	blue-300
    #021BFF	blue-800
    #035ADE	blue-800
    #4F52C5	blue-800
    #3C44B1	blue-800
    #E0FDEC	green-100
    #DFF9EC	green-100
    #D5F6F3	green-100
    #CDFFE2	green-100
    #D9F1F1	green-100
    #E0FFFF	green-100
    #4ED485	green-300
    #01A3A4	green-400
    #01A4A4	green-400
    #F5F5F5	neutral-100
    #FAFAFA	neutral-100
    #F4F4F4	neutral-100
    #F2FAFD	neutral-100
    #F9F9F9	neutral-100
    #E9ECF1	neutral-200
    #F2F2F2	neutral-200
    #F5F4F2	neutral-200
    #F1F4F8	neutral-200
    #EFEFFF	neutral-200
    #F1F1F1	neutral-200
    #E9E9E9	neutral-200
    #E5E5E5	neutral-300
    #D3D4DC	neutral-300
    #EDEBE8	neutral-300
    #DDDAD6	neutral-300
    #D6DBE4	neutral-300
    #D2D2D2	neutral-400
    #D1D2DB	neutral-400
    #ADB2BA	neutral-400
    #C2C4C6	neutral-400
    #ADB2B9	neutral-400
    #D3D3D3	neutral-400
    #D5D5D5	neutral-400
    #C4C4C4	neutral-400
    #A2A2A2	neutral-500
    #979797	neutral-500
    #969798	neutral-500
    #897F86	neutral-500
    #959BA3	neutral-500
    #98A0AB	neutral-500
    #828B93	neutral-600
    #797672	neutral-600
    #8F96A0	neutral-600
    #79808D	neutral-600
    #6E5E5E	neutral-700
    #6B6A66	neutral-700
    #FFDD88	orange-100
    #FFDFC8	orange-100
    #FFD993	orange-100
    #FFE173	orange-100
    #FFC86E	orange-200
    #F5D06B	orange-200
    #FFBA49	orange-300
    #FFB949	orange-300
    #FFF2CE	orange-50
    #FFFBE6	orange-50
    #FFFBF2	orange-50
    #FFF1CD	orange-50
    #FFF0D4	orange-50
    #FFF6CA	orange-50
    #FF9E0C	orange-500
    #FF8F00	orange-600
    #818BA4	purplish-500
    #7A7B97	purplish-500
    #AF5E6D	purplish-500
    #6084B6	purplish-500
    #4E5A6B	purplish-600
    #364256	purplish-600
    #415066	purplish-600
    #1E2C42	purplish-800
    #1F2C41	purplish-800
    #3B3E66	purplish-800
    #371C36	purplish-800
    #343434	purplish-800
    #3A2935	purplish-800
    #2B3444	purplish-800
    #1A4688	purplish-800
    #000000	purplish-900
    #070919	purplish-900
    #21191D	purplish-900
    #26131F	purplish-900
    #FFAE8B	red-100
    #FDC7C4	red-100
    #FF6285	red-300
    #FF73C7	red-300
    #FFF3E9	red-50
    #FFEFEF	red-50
    #FC6E47	red-500
    #F86158	red-600
    #F45255	red-600
    #F83245	red-600
    #FF3366	red-600
    #B11E1E	red-900
    #FFFFFF	neutral-50
------------------------------------*/
/*--------Old Color Variables----------------------------
    $Salmon:            #F86158;
    $Midnight:          #1F2C41;
    $Eggshell:          #FFF2CE;
    $Gold:              #FFBA49;
    $White:             #FFFFFF;
    $OxfordBlue:        #364256;
    $ShadowBlue:        #818BA4;
    $Solitude:          #E9ECF1;
    $Snow:              #F9F9F9;
    $Emerald:           #4ED485;
    $GoldenGlow:        #FFDD88;
    $PeachGlow:         #FFDFC8;
    $Black_30:          #21111A; // not used
    $RedInvited:        #FC6E47; // not used
    $BlueSent:          #6084B6; // not used
    $BlueCompleted:     #035ADE; // not used
    $GreenLight:        #4ED485; // not used
------------------------------------*/

.floating-label-input__outer-wrapper.input-read-only {
  opacity: 0.85;
  pointer-events: none;
}

.floating-label-input-wrapper {
  height: 3rem;
  position: relative;
  background: #fff;
  border-radius: 2.5rem;
}
.floating-label-input-wrapper input {
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #DDDAD6;
  outline: none;
  padding-inline: 1.5rem;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #73706C;
  position: relative;
  z-index: 2;
  background: transparent;
}
.floating-label-input-wrapper input:hover, .floating-label-input-wrapper input:active, .floating-label-input-wrapper input:focus {
  box-shadow: none;
  border-color: #42B4B5;
}
.floating-label-input-wrapper input.input-active, .floating-label-input-wrapper input:-webkit-autofill {
  padding-top: 0.7rem;
  border-color: #42B4B5;
}
.floating-label-input-wrapper input.input-active + label, .floating-label-input-wrapper input:-webkit-autofill + label {
  top: 0;
  left: 1rem;
  background-color: #fff;
  padding: 0 0.5rem;
  font-size: 0.625rem;
  font-weight: 360;
  color: #01A4A4;
}
.floating-label-input-wrapper input.input-active + label::after, .floating-label-input-wrapper input:-webkit-autofill + label::after {
  font-size: inherit;
  margin-top: 0;
}
.floating-label-input-wrapper input[type=number] {
  -webkit-appearance: textfield;
          appearance: textfield;
}
.floating-label-input-wrapper input[type=number]::-webkit-inner-spin-button,
.floating-label-input-wrapper input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.floating-label-input-wrapper label {
  position: absolute;
  padding: 0 1.5rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.875rem;
  transition: all 0.2s ease-in;
  margin-bottom: 0;
  line-height: 1.375rem;
  z-index: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #73706C;
  cursor: text;
  pointer-events: none;
}
.floating-label-input-wrapper.input-required > label:after {
  content: "*";
  color: #E77D72;
  margin-left: 0.2rem;
  font-weight: 500;
  font-size: 1.125rem;
  margin-top: 0.125rem;
  position: absolute;
}
.floating-label-input-wrapper.input-disabled input {
  background-color: #DDDAD6;
  border-color: #DDDAD6;
  pointer-events: none;
  opacity: 0.8;
}
.floating-label-input-wrapper.input-disabled label {
  pointer-events: none;
}
.floating-label-input-wrapper + .input-description {
  color: rgba(31, 44, 65, 0.6);
  padding-left: 1.5rem;
  font-size: 0.75rem;
}
.floating-label-input-wrapper.input-error input {
  border: 1px solid #E77D72 !important;
}
.floating-label-input-wrapper.input-error + div.error-message {
  color: #E77D72;
  padding-left: 1.5rem;
}
.floating-label-input-wrapper .floating-label-input-icon {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.625rem;
  z-index: 2;
}
.floating-label-input-wrapper .floating-label-input-icon__wrapper {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.625rem;
  z-index: 2;
  height: 1rem;
}
.floating-label-input-wrapper .floating-label-input-icon__up-down-arrow {
  width: 1rem;
  position: relative;
}
.floating-label-input-wrapper .floating-label-input-icon__up-down-arrow i {
  cursor: pointer;
}
.floating-label-input-wrapper .floating-label-input-icon__up-down-arrow i#fi-icon-faChevronUp {
  top: 10%;
}
.floating-label-input-wrapper .floating-label-input-icon__up-down-arrow i#fi-icon-faChevronDown {
  top: 100%;
}
.floating-label-input-wrapper .floating-label-input-icon > svg {
  vertical-align: middle;
}
.floating-label-input-wrapper--multiple-icons input {
  padding-right: 4rem;
}
.floating-label-input-wrapper--multiple-icons .floating-label-input-icon__wrapper {
  width: 2.5rem;
}

.ca-input-tag-value {
  background: #DDDAD6;
  border-radius: 2.5rem;
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  overflow: hidden;
}

.session-focus-tags {
  background-color: #FFFFFF !important;
  border: 1px solid;
}
.session-focus-tags:hover {
  background-color: #f2f2f2 !important;
}
/*--------Color Mapping----------------------------
    #EBF3FF	blue-100
    #BFE8FF	blue-100
    #BFE8FE	blue-100
    #D0E4FF	blue-100
    #52C0E5	blue-300
    #021BFF	blue-800
    #035ADE	blue-800
    #4F52C5	blue-800
    #3C44B1	blue-800
    #E0FDEC	green-100
    #DFF9EC	green-100
    #D5F6F3	green-100
    #CDFFE2	green-100
    #D9F1F1	green-100
    #E0FFFF	green-100
    #4ED485	green-300
    #01A3A4	green-400
    #01A4A4	green-400
    #F5F5F5	neutral-100
    #FAFAFA	neutral-100
    #F4F4F4	neutral-100
    #F2FAFD	neutral-100
    #F9F9F9	neutral-100
    #E9ECF1	neutral-200
    #F2F2F2	neutral-200
    #F5F4F2	neutral-200
    #F1F4F8	neutral-200
    #EFEFFF	neutral-200
    #F1F1F1	neutral-200
    #E9E9E9	neutral-200
    #E5E5E5	neutral-300
    #D3D4DC	neutral-300
    #EDEBE8	neutral-300
    #DDDAD6	neutral-300
    #D6DBE4	neutral-300
    #D2D2D2	neutral-400
    #D1D2DB	neutral-400
    #ADB2BA	neutral-400
    #C2C4C6	neutral-400
    #ADB2B9	neutral-400
    #D3D3D3	neutral-400
    #D5D5D5	neutral-400
    #C4C4C4	neutral-400
    #A2A2A2	neutral-500
    #979797	neutral-500
    #969798	neutral-500
    #897F86	neutral-500
    #959BA3	neutral-500
    #98A0AB	neutral-500
    #828B93	neutral-600
    #797672	neutral-600
    #8F96A0	neutral-600
    #79808D	neutral-600
    #6E5E5E	neutral-700
    #6B6A66	neutral-700
    #FFDD88	orange-100
    #FFDFC8	orange-100
    #FFD993	orange-100
    #FFE173	orange-100
    #FFC86E	orange-200
    #F5D06B	orange-200
    #FFBA49	orange-300
    #FFB949	orange-300
    #FFF2CE	orange-50
    #FFFBE6	orange-50
    #FFFBF2	orange-50
    #FFF1CD	orange-50
    #FFF0D4	orange-50
    #FFF6CA	orange-50
    #FF9E0C	orange-500
    #FF8F00	orange-600
    #818BA4	purplish-500
    #7A7B97	purplish-500
    #AF5E6D	purplish-500
    #6084B6	purplish-500
    #4E5A6B	purplish-600
    #364256	purplish-600
    #415066	purplish-600
    #1E2C42	purplish-800
    #1F2C41	purplish-800
    #3B3E66	purplish-800
    #371C36	purplish-800
    #343434	purplish-800
    #3A2935	purplish-800
    #2B3444	purplish-800
    #1A4688	purplish-800
    #000000	purplish-900
    #070919	purplish-900
    #21191D	purplish-900
    #26131F	purplish-900
    #FFAE8B	red-100
    #FDC7C4	red-100
    #FF6285	red-300
    #FF73C7	red-300
    #FFF3E9	red-50
    #FFEFEF	red-50
    #FC6E47	red-500
    #F86158	red-600
    #F45255	red-600
    #F83245	red-600
    #FF3366	red-600
    #B11E1E	red-900
    #FFFFFF	neutral-50
------------------------------------*/
/*--------Old Color Variables----------------------------
    $Salmon:            #F86158;
    $Midnight:          #1F2C41;
    $Eggshell:          #FFF2CE;
    $Gold:              #FFBA49;
    $White:             #FFFFFF;
    $OxfordBlue:        #364256;
    $ShadowBlue:        #818BA4;
    $Solitude:          #E9ECF1;
    $Snow:              #F9F9F9;
    $Emerald:           #4ED485;
    $GoldenGlow:        #FFDD88;
    $PeachGlow:         #FFDFC8;
    $Black_30:          #21111A; // not used
    $RedInvited:        #FC6E47; // not used
    $BlueSent:          #6084B6; // not used
    $BlueCompleted:     #035ADE; // not used
    $GreenLight:        #4ED485; // not used
------------------------------------*/
@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -6px;
  position: absolute;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  box-sizing: content-box;
  position: absolute;
  border: 6px solid transparent;
  height: 0;
  width: 1px;
}
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  content: "";
  z-index: -1;
  border-width: 6px;
  left: -6px;
  border-bottom-color: rgba(7, 9, 25, 0.15);
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -6px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #01a3a4;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: rgba(7, 9, 25, 0.15);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -6px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: rgba(7, 9, 25, 0.15);
}

.react-datepicker-wrapper {
  display: block;
  z-index: 100;
}

.react-datepicker {
  background-color: #fff;
  color: #1e2c42;
  border-radius: 0.29rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
}

.datepicker-inline-wrapper .react-datepicker {
  box-shadow: none;
  width: 100%;
  border-radius: 0;
}
.datepicker-inline-wrapper .react-datepicker .react-datepicker__month-container {
  margin: 0;
  width: 100%;
}
.datepicker-inline-wrapper .react-datepicker .react-datepicker__navigation {
  top: 14px;
}
.datepicker-inline-wrapper .react-datepicker .react-datepicker__navigation--previous {
  left: 8px;
}
.datepicker-inline-wrapper .react-datepicker .react-datepicker__navigation--next {
  right: 8px;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}
.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
  border-color: #01a3a4;
}
.react-datepicker__triangle::before {
  display: none;
}
.react-datepicker__triangle::after {
  border-bottom-color: #01a3a4 !important;
  margin-top: -3px;
}

.react-datepicker-popper {
  z-index: 102;
}
.react-datepicker-popper[data-placement^=bottom] {
  margin-top: 8px;
}
.react-datepicker-popper[data-placement^=top] {
  margin-bottom: 8px;
}
.react-datepicker-popper[data-placement^=right] {
  margin-left: 6px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  margin-right: 6px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #01a3a4;
  border-top-left-radius: 0.29rem;
  border-top-right-radius: 0.29rem;
  padding-top: 10px;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: #fff;
  font-size: 0.75rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 28px;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  outline: none !important;
}
.react-datepicker__navigation--previous {
  left: 24px;
  border-right-color: #fff;
}
.react-datepicker__navigation--previous:hover {
  border-right-color: #fafafa;
}
.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  opacity: 0.5;
  cursor: default;
}
.react-datepicker__navigation--next {
  right: 24px;
  border-left-color: #fff;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}
.react-datepicker__navigation--next:hover {
  border-left-color: #fafafa;
}
.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  opacity: 0.5;
  cursor: default;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #d1d2db;
}
.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b4b6c5;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #d1d2db;
}
.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b4b6c5;
}

.react-datepicker__month-container {
  float: left;
  margin: 1rem;
}

.react-datepicker__month {
  text-align: center;
  border: #e6e7f1 solid 1px;
  border-top-width: 0;
  margin: 0;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid rgba(7, 9, 25, 0.15);
  width: 120px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -122px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 120px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  padding: 0;
  height: calc(195px + 2rem / 2);
  overflow-y: scroll;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 5px 10px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f4f5fd;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: theme-color("primary");
  color: #fff;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: theme-color("primary");
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #d1d2db;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #d1d2db;
  display: inline-block;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.29rem;
  background-color: theme-color("primary");
}

.react-datepicker__day-names {
  background: #fff;
  border: #e6e7f1 solid 1px;
  border-top-width: 0;
  margin-top: 10px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #1e2c42;
  font-weight: 700;
  display: inline-block;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  margin: 0.166rem;
  font-size: 0.75rem;
  border-radius: 100%;
}

.react-datepicker__day {
  cursor: pointer;
  border-radius: 100%;
  outline: none !important;
}
.react-datepicker__day.react-datepicker__day--outside-month {
  color: #dfe0ea;
}
.react-datepicker__day:hover {
  background-color: #01a3a4;
  color: #fff;
  border-radius: 50% !important;
}
.react-datepicker__day--highlighted {
  background-color: #01a3a4;
  color: #fff !important;
}
.react-datepicker__day--highlighted:hover {
  background-color: #018a8b;
}
.react-datepicker__day--highlighted-custom-1 {
  color: #f4772e;
}
.react-datepicker__day--highlighted-custom-2 {
  color: #01a3a4;
}
.react-datepicker__day--selected {
  background-color: #01a3a4;
  color: #fff !important;
}
.react-datepicker__day--selected:hover {
  background-color: #01a3a4;
  color: #fff !important;
}
.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  background-color: #f4f5fd !important;
  color: #070919 !important;
}
.react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: #f4f5fd !important;
  color: #070919 !important;
}
.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background-color: #01a3a4;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: #0fa5b4;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: #f4f5fd;
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #1bc;
  color: #fff;
}
.react-datepicker__day--disabled {
  cursor: default;
  color: #d1d2db;
}
.react-datepicker__day--disabled:hover {
  background-color: transparent;
  color: #d1d2db;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end {
  background-color: #1bc !important;
  color: #fff !important;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.29rem;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b4b6c5;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #d1d2db;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: theme-color("primary");
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.29rem;
  border: 1px solid rgba(7, 9, 25, 0.15);
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.29rem;
  border-top-right-radius: 0.29rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.29rem;
  border-bottom-right-radius: 0.29rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #d1d2db;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b4b6c5;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b4b6c5;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px;
}
.react-datepicker__close-icon::after {
  background-color: theme-color("primary");
  border-radius: 100%;
  bottom: 0;
  color: #fff;
  content: "×";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0px;
  text-align: center;
}

.react-datepicker__today-button {
  background: theme-color("primary");
  border-top: 1px solid rgba(7, 9, 25, 0.15);
  cursor: pointer;
  text-align: center;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1340;
  background-color: rgba(7, 9, 25, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 0.75rem;
}
.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
  margin-top: 3px;
}
.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #01a3a4;
}
.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #017171;
}
.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #d1d2db;
  cursor: default;
}
.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #01a3a4;
}
.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #017171;
}
.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #d1d2db;
  cursor: default;
}

.rangeButtonSelectedStyle {
  background: #01a3a4 !important;
}

.rangebuttonstyle {
  outline: none !important;
  color: #01a3a4 !important;
}

.custom-input-date-picker {
  width: 100%;
  position: relative;
  height: 3rem;
}
.custom-input-date-picker span {
  position: absolute;
  font-size: 0.875rem;
  padding-left: 1.5rem;
  color: #73706c;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.2s ease-in;
}
.custom-input-date-picker input {
  padding-left: 1.5rem;
  width: 100%;
  height: 100%;
}
.custom-input-date-picker input:focus, .custom-input-date-picker input:hover, .custom-input-date-picker input:valid {
  box-shadow: none;
  border-color: #42B4B5;
}
.custom-input-date-picker input:focus + span, .custom-input-date-picker input:valid + span {
  top: 0;
  left: 1rem;
  font-size: 0.675rem;
  background-color: #fff;
  padding: 0 0.5rem;
  color: #01A4A4;
}
.custom-input-date-picker .custom-icon-date-picker {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.modal-content {
  border-radius: 0.625rem;
  box-shadow: 0 5rem 14rem 0 hsla(0, 0%, 100%, 0.3), 0 0.8rem 2.3rem rgba(0, 0, 0, 0.6), 0 0.2rem 0.3rem rgba(0, 0, 0, 0.45);
}
.modal-content .modal-wrapper {
  padding: 1.25rem;
}
.modal-content .modal-wrapper button {
  box-shadow: 0 0.25rem 0.55rem rgba(1, 163, 164, 0.35);
  min-width: 6rem;
}
.modal-content .modal-wrapper button:hover {
  box-shadow: 0 0.22rem 0.525rem rgba(1, 163, 164, 0.4), 0 0.0625rem 0.385rem rgba(1, 163, 164, 0.54);
}
.modal-content .btn-yes {
  color: #fff;
  background-color: #01a3a4;
  border-color: #01a3a4;
}
.modal-content .btn-no {
  color: #01a3a4;
  background-color: #fff;
  border-color: #01a3a4;
}
.modal-content .font-size-xl {
  font-size: 1.3125rem;
}
.modal-content .cursor-pointer {
  cursor: pointer;
}

@media only screen and (min-width: 481px) {
  .modal-wrapper {
    padding: 1.5rem;
  }
}
/*--------Color Mapping----------------------------
    #EBF3FF	blue-100
    #BFE8FF	blue-100
    #BFE8FE	blue-100
    #D0E4FF	blue-100
    #52C0E5	blue-300
    #021BFF	blue-800
    #035ADE	blue-800
    #4F52C5	blue-800
    #3C44B1	blue-800
    #E0FDEC	green-100
    #DFF9EC	green-100
    #D5F6F3	green-100
    #CDFFE2	green-100
    #D9F1F1	green-100
    #E0FFFF	green-100
    #4ED485	green-300
    #01A3A4	green-400
    #01A4A4	green-400
    #F5F5F5	neutral-100
    #FAFAFA	neutral-100
    #F4F4F4	neutral-100
    #F2FAFD	neutral-100
    #F9F9F9	neutral-100
    #E9ECF1	neutral-200
    #F2F2F2	neutral-200
    #F5F4F2	neutral-200
    #F1F4F8	neutral-200
    #EFEFFF	neutral-200
    #F1F1F1	neutral-200
    #E9E9E9	neutral-200
    #E5E5E5	neutral-300
    #D3D4DC	neutral-300
    #EDEBE8	neutral-300
    #DDDAD6	neutral-300
    #D6DBE4	neutral-300
    #D2D2D2	neutral-400
    #D1D2DB	neutral-400
    #ADB2BA	neutral-400
    #C2C4C6	neutral-400
    #ADB2B9	neutral-400
    #D3D3D3	neutral-400
    #D5D5D5	neutral-400
    #C4C4C4	neutral-400
    #A2A2A2	neutral-500
    #979797	neutral-500
    #969798	neutral-500
    #897F86	neutral-500
    #959BA3	neutral-500
    #98A0AB	neutral-500
    #828B93	neutral-600
    #797672	neutral-600
    #8F96A0	neutral-600
    #79808D	neutral-600
    #6E5E5E	neutral-700
    #6B6A66	neutral-700
    #FFDD88	orange-100
    #FFDFC8	orange-100
    #FFD993	orange-100
    #FFE173	orange-100
    #FFC86E	orange-200
    #F5D06B	orange-200
    #FFBA49	orange-300
    #FFB949	orange-300
    #FFF2CE	orange-50
    #FFFBE6	orange-50
    #FFFBF2	orange-50
    #FFF1CD	orange-50
    #FFF0D4	orange-50
    #FFF6CA	orange-50
    #FF9E0C	orange-500
    #FF8F00	orange-600
    #818BA4	purplish-500
    #7A7B97	purplish-500
    #AF5E6D	purplish-500
    #6084B6	purplish-500
    #4E5A6B	purplish-600
    #364256	purplish-600
    #415066	purplish-600
    #1E2C42	purplish-800
    #1F2C41	purplish-800
    #3B3E66	purplish-800
    #371C36	purplish-800
    #343434	purplish-800
    #3A2935	purplish-800
    #2B3444	purplish-800
    #1A4688	purplish-800
    #000000	purplish-900
    #070919	purplish-900
    #21191D	purplish-900
    #26131F	purplish-900
    #FFAE8B	red-100
    #FDC7C4	red-100
    #FF6285	red-300
    #FF73C7	red-300
    #FFF3E9	red-50
    #FFEFEF	red-50
    #FC6E47	red-500
    #F86158	red-600
    #F45255	red-600
    #F83245	red-600
    #FF3366	red-600
    #B11E1E	red-900
    #FFFFFF	neutral-50
------------------------------------*/
/*--------Old Color Variables----------------------------
    $Salmon:            #F86158;
    $Midnight:          #1F2C41;
    $Eggshell:          #FFF2CE;
    $Gold:              #FFBA49;
    $White:             #FFFFFF;
    $OxfordBlue:        #364256;
    $ShadowBlue:        #818BA4;
    $Solitude:          #E9ECF1;
    $Snow:              #F9F9F9;
    $Emerald:           #4ED485;
    $GoldenGlow:        #FFDD88;
    $PeachGlow:         #FFDFC8;
    $Black_30:          #21111A; // not used
    $RedInvited:        #FC6E47; // not used
    $BlueSent:          #6084B6; // not used
    $BlueCompleted:     #035ADE; // not used
    $GreenLight:        #4ED485; // not used
------------------------------------*/

.chip-group-component .ca-horizontal-line {
  width: -webkit-fill-available;
  border-top: 1px solid #21111A;
  opacity: 0.1;
}
.chip-group-component .title {
  min-width: 158px;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}
.chip-group-component .chip-group-wrapper .chip-group-item {
  display: flex;
  border: 2px solid #21111A;
  border-radius: 2.5rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.563rem 1.25rem 0.563rem 0.75rem;
  margin: 0 1rem 1rem 0;
  font-size: 0.875rem;
  -webkit-user-select: none;
          user-select: none;
}
.chip-group-component .chip-group-wrapper .in-active {
  border: 2px solid rgba(33, 17, 26, 0.1);
}
.chip-group-component .chip-group-wrapper .cursor-pointer {
  cursor: pointer;
}
.feedback {
  font-family: "Readex Pro", sans-serif;
}
.feedback-group {
  margin-bottom: 3.75rem;
}
.feedback-group-title {
  font-size: 1.625rem !important;
}
.feedback-group-btn__submit, .feedback-group-btn__view-dashboard {
  padding: 0.75rem 1rem;
  outline: none;
  background-color: #01A4A4;
  font-weight: 500;
  border: none;
}
.feedback-group-btn__submit:not([disabled]):hover, .feedback-group-btn__submit:not([disabled]):active, .feedback-group-btn__submit:not([disabled]):focus, .feedback-group-btn__view-dashboard:not([disabled]):hover, .feedback-group-btn__view-dashboard:not([disabled]):active, .feedback-group-btn__view-dashboard:not([disabled]):focus {
  background-color: #009492;
  box-shadow: none;
}
.feedback-group-btn__cancel {
  padding: 0.75rem 1rem;
  outline: none;
  background-color: #fff;
  color: #211B21;
  border: 1px solid #211B21;
  font-weight: 500;
}
.feedback-group-btn__cancel:not([disabled]):hover, .feedback-group-btn__cancel:not([disabled]):active, .feedback-group-btn__cancel:not([disabled]):focus {
  background-color: #fff;
  color: #211B21;
  box-shadow: none;
}
.feedback-note {
  font-size: 0.75rem;
  margin-top: 1.25rem;
  margin-bottom: 0;
  font-weight: 360;
  color: #000;
}
.feedback-dialog {
  width: 14.375rem;
  height: 11.25rem;
  padding: 1.25rem 1.25rem 0 1.25rem;
  background: #fff;
  display: flex;
  box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.11);
  position: fixed;
  right: 1.25rem;
  top: 40%;
  border-radius: 8px;
  z-index: 999;
}
.feedback-dialog p {
  margin-bottom: 0;
  font-size: 0.75rem;
  font-family: "Readex Pro", sans-serif;
}
.feedback-dialog button {
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 0.5rem;
  color: #211B21;
  background: #fff;
  border: none;
}
.feedback-dialog button:not([disabled]):hover, .feedback-dialog button:not([disabled]):active, .feedback-dialog button:not([disabled]):focus {
  background-color: #fff !important;
  color: #211B21 !important;
  box-shadow: none !important;
}
.feedback .font-weight-600 {
  font-weight: 600;
}
.feedback .mb-36 {
  margin-bottom: 2.25rem;
}

.feedback-contact p {
  font-family: "Readex Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
  color: #211B21;
}
.feedback-contact p span {
  color: #035BDE;
}

.inset-0 {
  inset: 0;
}

.z-99 {
  z-index: 99;
}

@media (max-width: 768px) {
  .feedback-group {
    margin-bottom: 1.5rem;
  }
  .feedback-group-title {
    font-size: 1.25rem !important;
  }
  .feedback-contact p {
    font-size: 1.25rem;
  }
}
@media (max-width: 575.98px) {
  .feedback-contact p {
    font-size: 0.8rem;
  }
}
